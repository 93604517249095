<template>
  <div class="selectedArea">
    <!-- v-el-select-loadmore="loadMore"  :loading="loading" -->
    <el-select 
      class="selected"
      v-model="selectValue" 
      :disabled="disabled"
      placeholder="请选择"
      filterable
      clearable
      remote
      :remote-method="remoteMethod"
      reserve-keyword
      @keyup.enter.native="enterKey"
      @change="change"
      v-el-select-loadmore="loadMore"
      @clear="handleClear">
        <el-option
        v-for="item in selectList"
        :key="item.userId"
        :label="item.userName"
        :value="item.userId">
        </el-option>
    </el-select>
  </div>
</template>

<script>
import {fetchUserSelect} from '@/api/backendall/memberAccount'
import {debounce} from '@/utils'

export default {
  name: 'Selected',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 2, //用户类型：2大喜 3小喜
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      selectList: [],
      selectValue: '',
      params: {
        pageNum: 1,
        pageSize: 50, // 3300
        userType: this.type,
        userName: '',
      },
      loading: false,
      total: 0,
    }
  },
  watch: {
    value: {
      handler: function(newVal){
        this.selectValue = newVal
      }
    },
    type: {
      handler: function(newVal){
        console.error('newVal', newVal)
        this.params.userType = newVal
        this.params.userType && this.loadAreaList(true)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    // this.loadAreaList()
  },
  methods: {
    // 防抖
    debouncer(func, params) {
      let _delay = 200;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        func(params);
      }, _delay);
    },
    remoteMethod(val){
      this.params.userName = val
      this.debouncer(this.loadAreaList, 500)
    },
    remoteMethod2(val){
      console.log('val:', val)
      if(!val) return
      this.params.userName = val
      const ifReset = true
      this.loadAreaList(ifReset)
    },
    handleClear(){
      this.params.userName = ''
      const ifReset = true
      this.loadAreaList(ifReset)
    },
    enterKey(event){
      const componentName = this.$options.name
      if (componentName == 'selectedArea') {
        const code = event.keyCode
          ? event.keyCode
          : event.which
          ? event.which
          : event.charCode
        if (code == 13) {
          const ifReset = true
          this.loadAreaList(ifReset)
        }
      }
    },
    handleFilter(key){
      this.params.userName = key
      const ifReset = true
      this.loadAreaList(ifReset)
    },
    change(val){
      this.$emit('input', val)
      // this.$emit('update:code', val)
    },
    async loadAreaList(ifReset){
      if(!this.type) return this.$message.error('请传入账号类型')
      if(ifReset){
        this.selectList = []
        this.params.pageNum = 1
      }
      this.params.userType = this.type
      const res = await fetchUserSelect(this.params)
      this.total = Number(res.data.total)
      this.selectList = this.selectList.concat(res.data.rows)
    },
    loadMore(){
      if(this.selectList.length >= this.total) return
      this.params.pageNum ++
      this.loadAreaList()
    },
  },
  directives: {//注意：这个是写到data外面
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            // throttle(binding.value(), 5000)
            binding.value()
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.selectedArea{
  .el-select {
      /* display: inline-block; */
      display: block !important;
      position: relative;
  }
}
</style>