<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
      <el-form-item prop="accountName" label="账号">
        <div> {{formData.accountName}} </div>
      </el-form-item>
      <el-form-item prop="tradePassword" label="密码">
        <div> *** </div>
      </el-form-item>
      <!-- <el-form-item prop="tradePassword" label="交易密码">
        <div> *** </div>
      </el-form-item> -->
      <el-form-item prop="phone" label="手机号">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item prop="type" label="账号类型">
        <div> {{ getType(formData.type) }} </div>
      </el-form-item>
      <el-form-item prop="createDate" label="注册日期">
        <div> {{formData.createDate}} </div>
      </el-form-item>
      <!-- <el-form-item prop="region" label="所属区域">
        <div> {{formData.region}} </div>
      </el-form-item> -->

      <el-form-item v-if="type == 1 || type == 4" prop="createDate" label="注册日期">
        <div> {{formData.createDate}} </div>
      </el-form-item>

      <div v-if="type === 2 || type ===3">
        <el-form-item prop="contacts" label="联系人">
          <div> {{formData.contacts}} </div>
        </el-form-item>
        <el-form-item prop="contactsPhone" label="联系电话">
          <div> {{formData.contactsPhone}} </div>
        </el-form-item>
        <el-form-item prop="wxorqq" label="其他联系(QQ/WX)">
          <div> {{formData.wxorqq}} </div>
        </el-form-item>
        <el-form-item prop="mailbox" label="邮箱">
          <div> {{formData.mailbox}} </div>
        </el-form-item>
        <el-form-item prop="addressDetail" label="联系地址">
          <div> {{formData.addressDetail}} </div>
        </el-form-item>
        <el-form-item prop="signDate" label="签约日期">
          <div> {{formData.signDate}} </div>
        </el-form-item>
      </div>

      <el-form-item prop="status" label="账号状态">
        <div> {{formData.status == 0 ? '冻结' : '正常'}} </div>
      </el-form-item>
      
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getAccountById} from '@/api/backendall/memberAccount'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
    type: {  //账号类型
      type: Number,
      default: null, // 1:普通用户  2:酷喜账号 3:厂家账号 4:内部账号
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.loadAccountById()
      }
    }
  },
  methods: {
    async loadAccountById(){
      this.loading = true
      const res = await getAccountById(this.itemId)
      this.formData = res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
    getType(type){
      let text = ''
      this.$store.state.user.accountType.forEach(item => {
        if(type == item.value) {
          text = item.name
        }
      })
      return text
    }
  },
  filters: {
    filterStatus: function(value){
      const accountTypeList = [
        {name: '普通用户', value: '1'},
        {name: '酷喜', value: '2'},
        {name: '厂家', value: '3'},
        {name: '内部账户', value: '4'},
      ]
      if(!value) return null
      return accountTypeList[value].name
    }
  },
}
</script>

<style>

</style>