<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="userName" label="用户昵称" verify>
          <el-input v-model="formData.userName" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item prop="accountName" label="账号" verify>
          <el-input v-model="formData.accountName" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="loginPassword" label="密码" verify>
          <el-input v-model="formData.loginPassword" placeholder="请输入密码" show-password :readonly="false"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="账号类型" verify>
          <gl-option v-model="formData.type" :list="$store.state.user.accountType"></gl-option>
        </el-form-item>
        
        <!-- <el-form-item prop="region" label="所属区域" verify>
          <selectedArea  v-model="formData.region"/>
        </el-form-item> -->

        <div v-if="formData.type == 2 || formData.type == 3 || formData.type == 5">
          <el-form-item prop="contacts" label="联系人" verify>
            <el-input v-model="formData.contacts" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item prop="contactsPhone" label="联系电话" verify>
            <el-input v-model="formData.contactsPhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item prop="wxorqq" label="其他联系(QQ/WX)" verify>
            <el-input v-model="formData.wxorqq" placeholder="请输入其他联系(QQ/WX)"></el-input>
          </el-form-item>
          <el-form-item prop="mailbox" label="邮箱" verify>
            <el-input v-model="formData.mailbox" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="addressDetail" label="联系地址" verify>
            <el-input v-model="formData.addressDetail" placeholder="请输入联系地址"></el-input>
          </el-form-item>
          <el-form-item prop="isSign" label="是否签约" verify>
            <gl-option v-model="formData.isSign" :list="signType"></gl-option>
          </el-form-item>
          <el-form-item v-if="formData.isSign == 1" prop="signDate" label="签约日期" verify>
            <el-date-picker
              v-model="formData.signDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item v-if="formData.type == 3" prop="factoryNum" label="厂家编号" verify>
          <el-input v-model="formData.factoryNum" placeholder="请输入厂家编号"></el-input>
        </el-form-item>

        <el-form-item v-if="formData.type == 5" prop="cityId" label="所属大喜" verify>
          <Selected v-model="formData.cityId" :type="2"/>
        </el-form-item>
        <el-form-item v-if="formData.type == 1" prop="regionId" label="所属小喜" verify>
          <Selected v-model="formData.regionId" :type="3"/>
        </el-form-item>

        <!-- Selected组件 用户类型：2大喜 3小喜 :type="3" -->
        <el-form-item v-if="formData.type == 5" prop="inviteRegionId" label="邀请小喜" verify>
          <Selected v-model="formData.inviteRegionId" :type="3"/>
        </el-form-item>

      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import base64 from '@/utils/base64'
import {addAccount} from '@/api/backendall/memberAccount'
import selectedArea from '@/components/selected-area.vue'
import Selected from './selected.vue'
export default {
  components: {selectedArea, Selected},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      formData: {},
      loading: false,
      signType: [
        {name: '否', value: '0'},
        {name: '是', value: '1'},
      ],
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        const loginPassword = base64.base64Encode(this.formData.loginPassword) // window.btoa(this.formData)
        let params = {
          ...this.formData,
          signDate: new Date(this.formData.signDate).getTime(),
          isSign: Number(this.formData.isSign),
          loginPassword,
        }
        // if(this.formData.isSign == 1){
        //   params.isSign = Number(this.formData.isSign)
        //   params.signDate = new Date(this.formData.signDate).getTime()
        // }
        this.loading = true
        try{
          const res = await addAccount(params)
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        }catch(e){
          this.loading = false
        }
        
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>