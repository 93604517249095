import request from '@/plugins/request'

// 会员管理-账号管理-列表
export function getAccountList(data = {}){
  if(data.status) data.status = Number(data.status)
  return request({
    url: `/api/user/account/getAccountList`,
    method: 'POST',
    data: data,
  })
}


// 会员管理-账号管理-账号详情
export function getAccountById(accountId = ''){
  let params = {
    accountId: accountId
  }
  return request({
    url: '/api/user/account/getAccountById',
    method: 'GET',
    params,
  })
}

// 会员管理-账号管理-账号新增
export function addAccount(data){
  return request({
    url: '/api/user/account/addAccount',
    method: 'POST',
    data,
  })
}

// 会员管理-账号管理-账号修改
export function updateAccount(data){
  return request({
    url: '/api/user/account/updateAccount',
    method: 'POST',
    data,
  })
}


// 获取区域
export function getRegionList(data = {}){
  return request({
    url: '/api/user/masRegion/getMasRegionList',
    method: 'POST',
    data,
  })
}

//获取大小喜 所属ID
export function fetchUserSelect(params = {}){
  return request({
    url: '/api/user/invoice/getUserSelect',
    method: 'POST',
    data: params,
  })
}



export default {
  getAccountList,
  getAccountById,
  addAccount,
  updateAccount,
  getRegionList,
  fetchUserSelect,
}