<template>
  <div>
    <gl-title title="账号管理"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="onClickAction('addVisible')"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="userId" label="用户ID">
          <gl-list-search v-model="mFormData.userId" placeholder="用户ID"></gl-list-search>
        </el-form-item>
        <el-form-item prop="accountName" label="账号">
          <gl-list-search v-model="mFormData.accountName" placeholder="账号"></gl-list-search>
        </el-form-item>
        <el-form-item prop="userName" label="用户昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="用户昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <gl-list-search v-model="mFormData.phone" placeholder="手机号"></gl-list-search>
        </el-form-item>
        <el-form-item prop="type" label="账号类型">
          <gl-option v-model="mFormData.type" :list="accountTypeList"></gl-option>
        </el-form-item>
        <el-form-item prop="status" label="账号状态">
          <gl-option v-model="mFormData.status" :list="accountStatus"></gl-option>
        </el-form-item>
        <el-form-item prop="accDateStart" label="注册日期">
          <gl-date-picker :startTime.sync="mFormData.accDateStart" :endTime.sync="mFormData.accDateEnd"></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="userId" label="用户ID" width="150"></el-table-column>
        <el-table-column prop="accountName" label="账号"></el-table-column>
        <el-table-column prop="userName" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="type" label="账号类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">普通用户</span>
            <span v-if="scope.row.type == 2">喜搭档</span>
            <span v-if="scope.row.type == 3">厂家</span>
            <span v-if="scope.row.type == 4">内部账号</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="账号状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">正常</span>
            <span v-if="scope.row.status == 2">冻结</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="操作" width="130px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="onClickAction('updateVisible', scope.row)"> 编辑 </el-button>
            <el-button type="text" @click="onClickAction('previewVisible', scope.row)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <addAccount :visible.sync="addVisible" @reload="mReload"/>
    <update :visible.sync="updateVisible" :itemId="itemId" :type="userType" @reload="mReload"/>
    <preview :visible.sync="previewVisible" :itemId="itemId" :type="userType"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
import addAccount from './addAccount.vue'
import {getAccountList} from '@/api/backendall/memberAccount'
export default {
  components: {update,preview,addAccount},
  mixins: [ListMixin],
  data(){
    return{
      addVisible: false,
      updateVisible: false,
      previewVisible: false,
      itemId: '',
      userType: null,
      accountStatus: [
        {name: '正常', value: '1'},
        {name: '冻结', value: '2'},
      ],
    }
  },
  computed: {
    accountTypeList(){
      let item = {name: '全部', value: ''}
      return [item, ...this.$store.state.user.accountType]
    },
  },
  mounted(){
    this.mGetListFun = getAccountList
    this.mGetList()
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item?.accountId || ''
      this.userType = item?.type || null
      this[name] = true
    }
  },
}
</script>

<style>

</style>