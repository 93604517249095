<template>
  <el-dialog 
    title="修改"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="userName" label="用户昵称" verify>
          <div>{{formData.userName}}</div>
        </el-form-item>
        <el-form-item prop="accountName" label="账号" verify>
          <el-input v-model="formData.accountName" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="loginPassword" label="密码" verify>
          <el-input v-model="formData.loginPassword" placeholder="请输入密码" show-password :readonly="false"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="tradePassword" label="交易密码">
          <el-input v-model.number="formData.tradePassword" placeholder="请输入交易密码" show-password></el-input>
        </el-form-item> -->
        <el-form-item prop="phone" label="手机号">
          <div> {{formData.phone}} </div>
        </el-form-item>
        <el-form-item prop="type" label="账号类型">
          <div> {{ getType(formData.type) }} </div>
        </el-form-item>

        <!-- 普通用户 v-if="type == 1 || type == 4" -->
        <el-form-item prop="user" label="注册日期">
          <div> {{formData.createDate}} </div>
        </el-form-item>

        <!-- type=2酷喜 type=3厂家  v-if="type == 2 || type == 3" -->
          <!-- <el-form-item v-if="type == 2" prop="region" label="所属区域">
            <div>{{formData.region}}</div>
          </el-form-item> -->
        <div v-if="type == 2 || type == 3">
          <el-form-item prop="contacts" label="联系人">
            <div> {{formData.contacts}} </div>
          </el-form-item>
          <el-form-item prop="contactsPhone" label="联系电话">
            <div> {{formData.contactsPhone}} </div>
          </el-form-item>
          <el-form-item prop="wxorqq" label="其他联系(QQ/WX)">
            <div> {{formData.wxorqq}} </div>
          </el-form-item>
          <el-form-item prop="mailbox" label="邮箱">
            <div> {{formData.mailbox}} </div>
          </el-form-item>
          <el-form-item prop="addressDetail" label="联系地址">
            <div> {{formData.addressDetail}} </div>
          </el-form-item>
          <el-form-item prop="signDate" label="签约日期">
            <div> {{formData.signDate}} </div>
          </el-form-item>
        </div>
        <el-form-item v-if="type == 3" prop="factoryNum" label="厂家编号">
          <div> {{formData.factoryNum}} </div>
        </el-form-item>

        <el-form-item prop="status" label="账号状态" verify>
          <gl-option v-model="formData.status" :list="accountStatusList"></gl-option>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import base64 from '@/utils/base64'
import {getAccountById, updateAccount} from '@/api/backendall/memberAccount'
export default {
  name: 'update',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
    type: {  //账号类型
      type: Number,
      default: null,  // 1:普通用户  2:酷喜账号 3:厂家账号 4:内部账号
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      accountStatusList: [
        {name: '冻结', value: '0'},
        {name: '正常', value: '1'},
      ],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAccount()
      }
    }
  },
  methods: {
    onSubmit(){

      this.$refs.form.validate(async valid => {
        if(!valid) return false
        const loginPassword = base64.base64Encode(this.formData.loginPassword) // window.btoa(this.formData)
        let params = {
          ...this.formData,
          accountId: this.itemId,
          userName: this.formData.userName,
          accountName: this.formData.accountName,
          loginPassword,
          type: Number(this.formData.type),
        }
        this.loading = true
        try{
          const res = await updateAccount(params)
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        }catch(e){
          this.loading = false
        }
        
      })
    },
    async getAccount(){
      this.loading = true
      const res = await getAccountById(this.itemId)
      res.data.status = String(res.data.status)
      this.formData = res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
    getType(type){
      let text = ''
      this.$store.state.user.accountType.forEach(item => {
        if(type == item.value) {
          text = item.name
        }
      })
      return text
    }
  },
}
</script>

<style>

</style>